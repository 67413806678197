// -----------------------------------------------------------------------------
// This file contains resize styles.
// -----------------------------------------------------------------------------

.vertical-resize-iconbox  {
    width: 16px;
    height: 40px;
    transform: translateY(-50%);
    top: 50%;
    background-color: unset;
    
    .fig-resize-icon    {
        width: 16px;
        height: 40px;
    }
}

.horizontal-resize-iconbox  {
    width: 40px;
    height: 16px;
    left: 50%;
    transform: translateX(-50%);
    
    .fig-resize-icon    {
        width: 40px;
        height: 16px;
        transform: scaleY(-1);
    }
}


