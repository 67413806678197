// -----------------------------------------------------------------------------
// This file contains videos page styles.
// -----------------------------------------------------------------------------

.fig-videos-left-side {
  padding-bottom: 20px;

  .fig-videos-all-folders-list {
    margin-top: 7px;
    margin-bottom: 10px;
    min-height: 10px;
    //max-height: calc(100vh - 220px);
    max-height: calc(100vh - 220px - 72px);
    overflow-y: auto;

    .MuiListItem-gutters {
      padding-left: 0px;
      padding-right: 0px;
      border-bottom: 1px solid transparentize($blue-grey, 0.8);
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .selected {
      background-color: $folder-selected;
    }

    .fig-video-folder-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      padding-top: 10px;

      .fig-video-folder-name {
        font-weight: $font-semi-bold;
        font-size: $font-xlsmall;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 45px);

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      .fig-video-label-numbers {
        div:last-child {
          background-color: $light-grey;
          @include border-radius(5px);
          font-size: $font-xlsmall;
          font-weight: $font-semi-bold;
          padding: 6px 10px;
          min-width: 45px;
          text-align: center;
        }
      }
    }
  }

  .fig-create-folder-box {
    display: flex;
    width: 100%;

    > svg {
      color: $color-primary;
      width: 16px;
      height: 16px;
    }

    > button {
      display: flex;
      justify-content: flex-end;
      width: 20px;
      min-width: 27px;

      .fig-close-icon {
        width: 15px;
        height: 15px;
      }
    }

    .fig-create-folder-input-box {
      width: 100%;
      margin-right: 10px;
    }
  }
}

.fig-videos-right-side {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px - 72px);

  .filters-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filter-button {
      width: 30px;
      height: 30px;
      padding: 0px 15px;

      .fig-filters-icon {
        @include svg-icons(24px, 24px, $color-primary);
      }
    }

    .fig-filters-title-header {
      font-size: $font-regular;
      font-weight: $font-semi-bold;
    }

    .fig-filters-results {
      display: flex;
      //color: $color-primary;
      font-size: $font-xlsmall;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @at-root #{&}__each {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0px;
        font-size: $font-xlsmall;
        border: 1px solid $color-border;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
        background-color: $lighter-grey;
      }
    }
  }

  .fig-videos-right-side-header {
    width: 100%;
    margin: 15px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $folder-selected;
  }

  .fig-videos-pagination-mainbox {
    width: 100%;
    padding: 5px;
    display: flex;
    margin-top: auto;

    .fig-videos-pagination-holder {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
