// -----------------------------------------------------------------------------
// This file contains rubric styles.
// -----------------------------------------------------------------------------

.fig-video-details-matrix-selects-row  {
    margin-top: 25px;

    select {
        color: $color-font;
        font-size: $font-xlsmall;
        font-family: $font-base;

        + svg   {
            width: 13px;
            height: 6px;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01484 0L5.83984 3.7085L9.66484 0L10.8398 1.1417L5.83984 6L0.839844 1.1417L2.01484 0Z' fill='%23002E51'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            top: inherit;
        }
    }

    .MuiInput-underline:before  {
        border-bottom: 0px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 0px;
    }

    input   {
        color: $color-font;
        font-size: $font-xlsmall;
        font-family: $font-base;

        + svg   {
            width: 13px;
            height: 6px;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01484 0L5.83984 3.7085L9.66484 0L10.8398 1.1417L5.83984 6L0.839844 1.1417L2.01484 0Z' fill='%23002E51'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            top: inherit;
        }
    }

    .MuiFormControl-root    {
        div{
            font-family: $font-base;
        }
        
    }
 }

 .fig-video-details-matrix-main-container    {

    .fig-category-header    {
        display: flex;
        align-items: center;
        height: 80px;

        svg {
            @include svg-icons(16px, 16px, $color-primary);
        }

        span    {
            padding-left: 6px;
            font-size: $font-xlsmall;
            font-weight: $font-semi-bold;
        }
    }

    .fig-category-header-users  {
        display: flex;
        justify-content: flex-start;

        .fig-matrix-userbox    {
            border-left: 1px solid transparentize($color: $blue-grey, $amount: 0.80);
            height: 80px;
            width: 80px;
            padding: 10px 15px 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: $font-xxsmall;

            &:last-child   {
                border-right: 0px solid transparentize($color: $blue-grey, $amount: 0.80);    
            }

            svg {
                @include svg-icons(24px, 24px, $color-primary);
                margin-bottom: 6px;
            }
        }
    } 

    .fig-question-row   {
        background-color: transparentize($color: $bubble-grey, $amount: 0.40);
        border-bottom: 2px solid $color-white;
        
        .fig-question-text  {
            font-size: $font-xlsmall;
            display: flex;
            align-items: center;
            //border-bottom: 2px solid $color-white;

            div {
                padding: 20px;
            }
        }

        .fig-matrix-scores  {
            display: flex;
            justify-content: flex-start;

            .fig-matrix-scoresbox    {
                border-left: 1px solid transparentize($color: $blue-grey, $amount: 0.80);
                height: 80px;
                width: 80px;
                padding: 10px 15px 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: $font-size;
                font-weight: $font-bold;
                //border-bottom: 2px solid $color-white;
    
                &:last-child   {
                    border-right: 0px solid transparentize($color: $blue-grey, $amount: 0.80);    
                }
            }
        }
    }

    .fig-category-footer-left   {

        div {
            padding: 20px;
            text-align: right;
            font-size: $font-xlsmall;
            font-weight: $font-semi-bold;
        }
    }

    .fig-category-footer-right  {
        display: flex;
        justify-content: flex-start;

        .fig-matrix-average-scoresbox   {
            border-left: 1px solid transparentize($color: $blue-grey, $amount: 0.80);
                
                width: 80px;
                padding: 10px 15px 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: $font-size;
                font-weight: $font-bold;
                border-bottom: 2px solid $color-white;
    
                &:last-child   {
                    border-right: 0px solid transparentize($color: $blue-grey, $amount: 0.80);    
                }
        }
    }
 }

