// -----------------------------------------------------------------------------
// This file contains dialog/modal styles.
// -----------------------------------------------------------------------------

.fig-modal-dialog-main-container {
  padding: 40px 50px 40px 50px;

  .fig-modal-dialog-input {
    > label {
      color: $color-font;
      font-size: $font-xsmall;
      font-family: $font-base;
      font-weight: $font-semi-bold;
      transform: translate(0, 1.5px) scale(1);
    }

    textArea {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
      padding-bottom: 15px;
      padding-top: 10px;
    }

    input {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
      padding-bottom: 15px;
      padding-top: 10px;
    }

    &.MuiInputBase-root {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
    }
    label + .MuiInput-formControl {
      margin-top: 10px;
    }

    .MuiSelect-select {
      margin-top: 7px;
    }
  }

  > div {
    &.row {
      margin-bottom: 25px;
    }
    &.row:last-child {
      margin-bottom: 0px;
    }
  }

  .fig-modal-dialog-buttons {
    display: flex;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 5px;
    }
  }
}

.fig-modal-dialog-main-container-v2 {
  padding: 40px;

  > .row {
    > div {
      width: 100%;
    }
  }

  .modal-title {
    font-weight: $font-semi-bold;
    color: $font-color;
  }

  .modal-searchbox {
    .MuiInputBase-root {
      @include border-radius(3px);
      border: 1px solid $blue-grey;
      padding-top: 4px;
      padding-bottom: 4px;

      .MuiSvgIcon-root {
        color: $color-primary;
        margin-left: 10px;
      }

      .MuiInputBase-input {
        font-size: $font-xlsmall;
        color: $color-font;
        padding-top: 7px;
        padding-bottom: 6px;
      }
    }

    .MuiInput-underline {
      &:before {
        border-bottom: 0px;
      }

      &:after {
        border-bottom: 0px;
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom: 0px;
      }
    }
  }

  .delete-search {
    padding: 6px;
    right: 10px;
    position: absolute;

    .MuiSvgIcon-root {
      color: $color-primary;
      margin-left: 0px !important;
      width: 13px;
      height: 13px;
    }
  }

  .modal-list-box {
    .modal-list-item {
      @include border-radius(5px);
      border: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
      padding: 24px;
      margin-bottom: 10px;

      .MuiTypography-body1 {
        font-size: $font-regular;
        font-weight: $font-semi-bold;
        color: $color-font;
      }

      &.selected {
        border: 1px solid $dark-blue;
        background-color: $modal-row-selected;
      }

      .content-text {
        font-size: $font-regular;
      }

      .category-name {
        margin-top: -10px;
        margin-bottom: 10px;
        font-weight: $font-semi-bold;
      }

      .modal-user {
        svg {
          width: 50px;
          height: 50px;
          fill: none;
        }

        .username-name {
          font-size: $font-regular;
        }

        .username-adress {
          font-size: $font-xsmall;
        }
      }
      .MuiButton-root.Mui-disabled {
        border: none !important;
      }

      .MuiButton-containedSecondary {
        box-shadow: none;
        color: $color-white !important;
        background-color: $color-primary;
      }
    }

    .MuiListItemSecondaryAction-root {
      right: 24px;

      .MuiTypography-body2 {
        font-size: $font-regular;
        color: $color-font;
      }
    }
  }

  .modal-dialog-footer {
    // margin-left: -40px;
    // margin-right: -40px;
    // border-top: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
    padding-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    border-top: 1px solid transparentize($color: $blue-grey, $amount: 0.8);

    .modal-button-box {
      display: flex;

      button {
        margin-left: auto;
        margin-right: 40px;
        margin-top: 20px;
        box-shadow: none;
      }
    }
  }

  .modal-dialog-footer-two-buttons {
    padding-bottom: 20px;
    border-top: 1px solid transparentize($color: $blue-grey, $amount: 0.8);

    .modal-button-box {
      display: inline-flex;

      button {
        margin-top: 20px;
      }
    }
  }

  .fig-modal-dialog-edit {
    .MuiInput-underline:before {
      border-bottom: 0px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0px;
    }

    .MuiInputBase-root {
      padding-top: 4px;
      padding-bottom: 4px;

      .MuiInputBase-input {
        font-size: $font-size;
        color: $color-font;
        font-weight: $font-semi-bold;
        padding-top: 7px;
        padding-bottom: 6px;

        &:focus {
          color: $blue-grey;
        }
      }
    }

    .fig-edit-modal {
      padding: 6px;
      margin-left: auto;

      .MuiSvgIcon-root {
        color: $color-primary;
        width: 16px;
        height: 16px;
      }

      &.MuiIconButton-root:hover {
        background-color: unset;
      }
    }
  }

  .fig-score-container {
    .MuiInputBase-input {
      font-size: $font-large;
      font-weight: $font-bold;
      color: $blue-grey;
      text-align: right;
      padding: 0px 6px;
    }
  }

  .modal-add-button {
    width: 25px;
    height: 25px;
    margin-top: 6px;
    margin-left: 4px;
  }

  .modal-shared-to-chips-container  {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
    padding: 10px;

    > div {
      &.MuiChip-root  {
        margin: 5px;
      }
    }
  }
}

.fig-share-with-main-container {
  padding: 15px;
  border: 1px solid $color-popup-border;
  @include border-radius(3px);
  background-color: $color-white;
  min-height: 40px;
  position: relative;
  margin-top: 10px;

  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 7px;
    top: -6px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 6H1L4 1L7 6Z' fill='white'/%3E%3Cpath d='M1 5.92857L4 1' stroke='%23BFC9DD'/%3E%3Cpath d='M7 6.14286L4 1' stroke='%23BFC9DD'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    margin-left: 80%;
  }

  .left-side {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
      fill: none;
    }

    p {
      font-size: $font-xlsmall;
      padding-left: 5px;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-end;

    .fig-button-contained {
      padding: 5px 10px;
      font-size: $font-xxsmall;
      font-weight: $font-semi-bold;
      color: $dark-blue;
      background-color: transparentize($blue-grey, 0.8);
    }

    .fig-close-icon {
      width: 8px;
      height: 8px;
    }

    .MuiButton-label {
      line-height: 15px;
      height: 14px;
    }
  }

  .fig-share-video {
    background-color: $color-white;
    @include border-radius(3px);
    font-size: $font-xsmall;
    font-weight: $font-semi-bold;
    border: 1px solid transparentize($blue-grey, 0.8);
    width: 100%;
    margin-top: 20px;
    text-transform: capitalize;
  }
}


.modal-confirmation-dialog  {
  .attention-icon {
    width: 80px;
    height: 79px;
    margin-top: 60px;
  }

  @at-root #{&}__title  {
    font-size: $font-semi-regular;
    font-weight: $font-bold;
    text-align: center;
    padding-top: 15px !important;
    padding-bottom: 10px !important;

    .MuiTypography-h6 {
      font-size: $font-semi-regular;
      font-weight: $font-bold;
      font-family: $font-base;
    }
  }

  @at-root #{&}__content  {
    font-size: $font-xlsmall;
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .MuiTypography-body1  {
      font-size: $font-xlsmall;
      color: $color-font;
    }
  }

  @at-root #{&}__buttons {
    font-size: $font-xlsmall;
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 25px;
    margin-bottom: 60px;
    justify-content: center !important;
    
  }
}
