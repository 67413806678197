// -----------------------------------------------------------------------------
// This file contains Bordered Selector styles.
// -----------------------------------------------------------------------------

.fig-bordered-selector-container    {
    padding: 15px;
    border: 1px solid $color-font;
    font-family: $font-base;
    font-size: $font-small;
    @include border-radius(3px);
    background-color: $color-white;
    margin-top: 3px;

    .MuiButtonBase-root {
        padding: 0px;
    }

    .fig-bordered-selector-textbox  {
        min-height: 24px;
        display: flex;
        align-items: center;

        label   {
            margin-bottom: 0px;
            width: 100%;
            padding-right: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
            z-index: $zIndex-lvl1;

            &:hover {
                cursor: pointer;
            }
        }
    }

    > .row {
        padding-bottom: 6px;
        
    }
    > .row {
        &:last-child    {
            padding-bottom: 0px;
        }
    }

    .fig-bordered-selector-icon {
        text-align: right;
        min-height: 24px;
        position: absolute;
        right: 0px;
        z-index: $zIndex-lvl0;

        svg {
            &.fig-check-icon {
              @include svg-icons(12px, 9px, $color-white);
            }

            &.fig-unchecked-icon {
                @include svg-icons(16px, 16px, $color-white);
                margin-top: 3px;
              }

            &.fig-checked-icon {
                @include svg-icons(16px, 16px, $color-white);
                margin-top: 3px;
            }
          }
        
        .MuiIconButton-label    {
            position: absolute;
            left: 10000px;
        }

        input {
            width: 0px;
            height: 0px;
            position: absolute;
            opacity: 0;
        }
    }
}
