// -----------------------------------------------------------------------------
// This file contains Login Container page styles.
// -----------------------------------------------------------------------------

.login-wrapper {
  position: absolute;
  display: flex;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 450px;

  > .MuiPaper-root  {
    width: 100%;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 6%), 0px 3px 14px 2px rgb(0 0 0 / 6%);
  }

  .login-wrapper__header {
    background-color: $dark-blue;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: center;

    .header-avatar {
      background-color: transparent;
      border: 2px solid $color-white;
      width: 64px;
      height: 64px;

      svg {
        font-size: 48px;
        fill: $color-white;
      }
    }

    .header-container {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      h1, p  {
        color: $color-white;
      }
    }
  }

  .login-wrapper__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;

    .login-wrapper__buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 30px;
      align-items: center;

      a {
        font-size: $font-regular;
        font-weight: $font-weight-regular;
        text-decoration: underline;
      }
    } 

    label, input  {
      font-family: $font-base;
      font-size: $font-regular;
    }

    input {
      
    }

    .MuiOutlinedInput-input {
      padding: 14px 14px;
    }

    .MuiFormControl-marginNormal  {
      margin-top: 25px;
    }

    .MuiOutlinedInput-notchedOutline  {
      border-radius: 0px;
      border-bottom: 1px solid $color-border;
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
    }
  }
}

.fig-login-failed {
  padding-left: 20px;
  padding-bottom: 10px;
  color: $color-red;
  display: flex;
  flex-direction: column;
  font-size: $font-regular;
}

.fig-login-reset-resend {
  padding-left: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: $font-regular;

  a {
    font-weight: $font-weight-regular;
    text-decoration: underline;
  }
}














