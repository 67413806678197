// -----------------------------------------------------------------------------
// This file contains rubrics page styles.
// -----------------------------------------------------------------------------

.fig-rubrics {
  .fig-rubrics-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    margin-left: -20px;
    border-bottom: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
    align-items: center;

    span {
      font-weight: $font-bold;
    }
  }

  .fig-rubrics-table-holder {
    width: 100%;
    margin-top: 40px;
    padding-right: 20px;

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .MuiTableHead-root {
      .MuiTableCell-stickyHeader {
        background-color: $color-white;
        border-bottom: 1px solid $color-white;

        .MuiButtonBase-root {
          font-size: $font-xlsmall;
          font-weight: $font-semi-bold;
          font-family: $font-base;

          span {
            margin-left: 4px;
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-right: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
        }

        &:nth-child(5) {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
        }

        &:nth-child(2) {
          padding-left: 0px;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        background-color: transparentize($color: $color-light-grey, $amount: 0.4);
        font-family: $font-base;
        border-bottom: 2px solid $color-white;
        font-size: $font-xlsmall;
        height: 60px;
        padding-right: 0px;

        button {
          svg {
            font-size: $font-xlsmall;
            color: $color-primary;

            &.fig-icon-delete-rubric-row {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      tr {
        .MuiTableCell-root {
          &:nth-child(3),
          &:nth-child(4) {
            border-right: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
          }

          &:nth-child(5) {
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
          }

          &:nth-child(2) {
            padding-left: 0px;
          }
        }
      }

      th {
        &.MuiTableCell-root {
          width: 30px;
          text-align: center;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }

  .fig-rubrics-your-new-rubric {
    width: 100%;
    height: calc(100vh - 140px);
    margin-top: 30px;
    padding-right: 20px;

    .fig-rubrics-left-side {
      padding-right: 40px;

      .fig-rubrics-left-side-title {
        font-size: $font-xlsmall;
        font-weight: $font-semi-bold;
      }

      .fig-rubrics-left-side-search {
        border: 1px solid transparentize($color: $blue-grey, $amount: 0.5);
        @include border-radius(4px);
        height: 40px;
        margin-top: 20px;

        form {
          height: 40px;

          button {
            margin-right: 5px;
          }
        }

        .MuiFormControl-root {
          width: 100%;
          height: 40px;

          label {
            font-size: 11px;
            color: transparentize($color: $color-font, $amount: 0.5);
            top: -10px;
            left: 15px;

            &.MuiInputLabel-shrink {
              background-color: $color-white;
              transform: translate(0px, 4px) scale(1);
            }
          }

          input {
            font-size: $font-regular;
            padding-left: 15px;
            font-family: $font-base;
          }

          label + .MuiInput-formControl {
            margin-top: 0px;
            height: 40px;
          }

          .MuiInput-underline:before {
            border-bottom: 0px;
          }

          .MuiInput-underline:after {
            border-bottom: 0px;
          }
        }
      }

      .fig-rubrics-left-side-list {
        height: calc(100vh - 280px);
        overflow: auto;
        margin-top: 20px;

        .MuiList-padding {
          margin-right: 10px;
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .MuiListItemText-root {
          > span {
            font-size: $font-xlsmall;
            font-weight: $font-semi-bold;
          }
        }
      }
    }

    .fig-rubrics-right-side {
      .fig-rubrics-right-side-title {
        font-size: $font-xlsmall;
        font-weight: $font-semi-bold;
      }

      .fig-rubrics-right-side-content {
        margin-top: 20px;
        @include border-radius(4px);
        border: 1px solid transparentize($color: $blue-grey, $amount: 0.8);

        > .MuiList-root {
          height: calc(100vh - 380px);
        }

        > form {
          margin: 40px 20px 30px 20px;

          .fig-rubrics-give-rubric-name {
            padding-right: 20px;

            label {
              font-size: $font-regular;
              color: transparentize($color: $color-font, $amount: 0.5);
              top: -16px;
            }

            label + .MuiInput-formControl {
              margin-top: 0px;
            }

            .MuiInputBase-input {
              font-size: $font-regular;
              padding-bottom: 9px;
              color: $color-primary;
            }
          }

          .fig-rubrics-select-type {
            .MuiInputBase-input {
              font-size: $font-regular;
              color: $color-primary;
            }
          }
        }

        .fig-rubrics-category-container {
          .MuiList-root {
            .MuiDivider-root {
              display: none;
            }
          }

          .fig-rubrics-category {

            > .fig-rubrics-category-item {
              border-top: 0px;
              padding: 20px;
              padding-bottom: 20px !important;
              border-bottom: 1px solid $color-divider;
            }
          }

          .fig-rubrics-category-item {
            padding: 5px 20px 5px 20px;
            background-color: $lighter-grey2;

            &:first-child {
              padding-top: 20px;
            }

            &:last-child {
              padding-bottom: 20px;
            }
            
            .MuiListItemIcon-root {
              min-width: 24px;
              margin-right: 20px;
            }

            .MuiFormControl-root {
              @include border-radius(3px);
              border: 1px solid transparentize($color: $blue-grey, $amount: 0.5);
              height: 60px;
              margin-right: 20px;
              width: 100%;
              background-color: $color-white;

              label {
                font-size: $font-xlsmall;
                left: 15px;

                &.MuiInputLabel-shrink {
                  transform: translate(0, 1.5px) scale(1);
                  transform-origin: top left;
                  top: 15px;
                  color: transparentize($color: $color-font, $amount: 0.5);
                  font-weight: $font-semi-bold;
                  font-size: $font-xxsmall2;
                }
              }

              .MuiInputBase-root {
                padding-left: 15px;
                font-size: $font-regular;
                padding-right: 15px;

                .MuiInputBase-input {
                  margin-top: 9px;
                }
              }

              .MuiInput-underline:before,
              .MuiInput-underline:after {
                border-bottom: 0px;
              }
            }

            .MuiIconButton-root {
              width: 60px;
              height: 60px;
              @include border-radius(3px);
              background-color: $color-white;

              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
              }

              .fig-icon-delete {
                @include svg-icons(21px, 22px, $color-primary);

                &.disabled {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }

      .fig-rubrics-right-side-buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
  }
}
