.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  /* border: 1px solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.MuiTab-root {
  padding: 0 !important;
  min-width: 72px !important;
  min-height: 48px !important;
  /* min-height: 28px !important;
  max-height: 28px !important; */
  text-align: center !important;
  flex-shrink: 0 !important;
  text-transform: none !important;
  /*
  margin-top: 1px;
  margin-bottom: 1px;
   position: 'sticky';
  top: 0; */

  /* .MuiTab-root :hover {
  background-color: red;
  border-color: red;
} */
}

.MuiBox-root {
  height: 100%;
  padding: 0px !important;
}

.graph-transcript-tab {
  height: 100% !important;
}

.MuiTypography-root {
  height: 100% !important;
}
