// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin clearfix {
  &:after {
      display: block;
      clear: both;
      content: '';
  }
}

@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin background-img--contain($image: null) {
  $path: $base-path;
  background: url($path + $image) center center no-repeat;
  background-size: contain;
}

@mixin background-img--cover($image:null) {
  $path: $base-path;
  background: url($path + $image) center center no-repeat;
  background-size: cover;
}

@mixin box-shadow--small {
  box-shadow: 0 4px 12px transparentize($color: $color-font, $amount: 0.8);
}

@mixin box-shadow--medium {
    box-shadow: 0 8px 20px transparentize($color: $color-font, $amount: 0.8);
}

@mixin box-shadow--large {
    box-shadow: 0 12px 30px transparentize($color: $color-font, $amount: 0.8);
}

@mixin effect {
  -webkit-transition: $transition-base;
  -moz-transition: $transition-base;
  transition: $transition-base;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin border($width, $color) {
  width: $width;
  height: 1px;
  background-color: $color;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ($width / $height) * 100%;
  }
  >.content {
      @include absolute(0,0,0,0);
  }
}

@mixin image-box($width, $heigh) {
  display: flex;
  width: $width;
  height: $heigh;
}

@mixin svg-icons($width, $height, $fill) {
    width: $width;
    height: $height;
    fill: $fill;
}