// -----------------------------------------------------------------------------
// This file contains PDF Renderer page styles.
// -----------------------------------------------------------------------------


.fig-pdf-round-logo {
  position: absolute;
  width: 50mm;
  height: 50mm;
  border-radius: 50%;
  background-color: $pdf-grey;
  left: 36mm;
  top: 60mm;
  text-align: center;
  padding-top: 2mm;

  img {
    width: 28mm;
    height: 42mm;
  }
}

.fig-pdf-renderer-title {
  position: absolute;
  top: 30%;
  left: 43%;
  font-size: 39.5pt;    
  font-family: $font-OpenSans-EB;      
  color: $pdf-blue;          
}

.fig-pdf-company-adress {
  position: absolute;
  top: 273mm;
  left: 47mm;
  font-family: $font-Arimo;
  font-style: italic;
  color: $pdf-blue;
  line-height: 1.3;
  font-size: 10.5pt;
}

.fig-pdf-coach-email {
  position: absolute;
  top: 273mm;
  right: 0;
  font-family: $font-Arimo;
  font-style: italic;
  color: $pdf-blue;
  line-height: 1.3;
  font-weight: 400 !important;
  font-size: 10.5pt;
}

.fig-pdf-company-url {
  position: absolute;
  top: 278.5mm;
  right: 0;
  font-family: $font-Arimo;
  font-style: italic;
  color: $pdf-blue;
  line-height: 1.3;
  font-weight: 400 !important;
  font-size: 10.5pt;
}

.fig-pdf-page-number {
  position: absolute;
  bottom: 5mm;
  right: 0;
  font-family: $font-Arimo;
  color: $pdf-blue;
  width: 167mm;
  text-align: center;
}

.fig-pdf-cover-salutation {
  position: absolute;
  top: 20%;
  font-size: 15pt;
  left: 47mm;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-weight: $font-semi-bold;

}

.fig-pdf-cover-intro {
  position: absolute;
  top: 25%;
  font-size: 12pt;
  left: 47mm;
  font-family: $font-Arimo;
  color: $pdf-blue;
 }


.fig-pdf-coach-name {
  position: absolute;  
  font-size: 15pt;
  bottom: 64mm;
  left: 47mm;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-weight: $font-semi-bold;

}

.fig-pdf-coach-role {
  position: absolute;  
  font-size: 12pt;
  bottom: 58mm;
  left: 47mm;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-style: italic;
}

.fig-pdf-company-name {
  position: absolute;  
  font-size: 12pt;
  bottom: 52mm;
  left: 47mm;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-style: italic;
}

.fig-pdf-highlight-mainbox {
  position: absolute;
  top: 35mm;
  left: 47mm;
  font-size: 12pt;
  font-family: $font-Arimo;
  color: $pdf-blue;
  display: flex;
  flex-direction: column;
  width: 163mm;

  @at-root #{&}__div1 {
    font-weight: $font-semi-bold;
    
  }
}

.fig-pdf-chart-and-image {
  margin-top: 7.5pt;

  .fig-pdf-image1 {
    width: 45%;
    margin-right: 5%;
    height: 120pt;
  }

  .fig-pdf-image2 {
    width: 45%;
    margin-left: 5%;
    height: 120pt;
  }
}

.fig-pdf-highlight-legend {
  display: flex;
  flex-direction: column;
  font-size: 10.5pt;
}

.fig-pdf-highlight-transcript {
  margin-top: 22.5pt;
  font-style: italic;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-size: 10.5pt;
  display: flex;
  flex-direction: row;
}

.fig-pdf-quote {
  width: 8.2mm;
  height: 6.3mm;
  margin-right: 15pt;
}

.fig-pdf-highlights-comments-main {
  margin-top: 22.5pt;
  display: flex;
}

.fig-pdf-comment-svg {
  width: 8.2mm;
  height: 6.8mm;
  margin-right: 15pt;
}

.fig-pdf-comment-holder {
  display: flex;
  flex-direction: column;
  font-family: $font-Arimo;
  color: $pdf-blue;
  font-size: 10.5pt;
  margin-bottom: 15pt;
}













