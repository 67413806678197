// -----------------------------------------------------------------------------
// This file contains Cards styles.
// -----------------------------------------------------------------------------

.fig-video-panel-card {
  //width: 335px;
  height: 275px;
  max-width: 400px;
  margin-right: 5px;
  margin-bottom: 3px;
  border: 1px solid transparentize($blue-grey, 0.5) !important;

  @at-root #{&}__actions {
    padding: 0px 16px !important;
    margin: 0px;

    @at-root #{&}-label {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  @at-root #{&}__checkbox {
    margin-left: auto;
    margin-top: 5px !important;
    display: flex !important;
    color: $color-primary !important;
    margin-right: -9px !important;
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  &.MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 6%), 0px 3px 14px 2px rgb(0 0 0 / 6%);
  }

  .fig-card-image {
    height: 140px;
    display: flex;

    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: auto;
      //height: 64px;
    }
  }

  .fig-card-title {
    font-size: $font-regular;
    font-weight: $font-semi-bold;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .fig-card-date {
    font-size: $font-xlsmall;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .fig-card-user {
    font-size: $font-xlsmall;
    margin-bottom: 2px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  }

  .fig-card-coach {
    font-size: $font-xlsmall;
    // margin-bottom: 5px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  }

  .MuiCardContent-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 3px;
  }

  .MuiCardActions-root {
    > span {
      margin-left: auto !important;
    }

    .fig-edit-icon {
      @include svg-icons(18px, 18px, $color-primary);
    }
  }
}
