// -----------------------------------------------------------------------------
// This file contains rubrics page styles.
// -----------------------------------------------------------------------------

.fig-request-a-video {
  
  .fig-request-a-video-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    margin-left: -20px;
    border-bottom: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
    align-items: center;

    span {
      font-weight: $font-bold;
    }
    
  }

  .fig-request-a-video-left-side  {
    padding-right: 40px;
    margin-top: 30px;

    @at-root #{&}__title  {
      font-size: $font-xlsmall;
      font-weight: $font-semi-bold;
    }

    @at-root #{&}__search-container  {
      border: 1px solid transparentize($color: $blue-grey, $amount: 0.5);
      @include border-radius(3px);
      height: 42px;
      padding: 0px 15px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover {
        border-bottom: 0px;
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 0px;
      }

      .MuiInputBase-input {
        font-size: 11px;
        //color: transparentize($color: $color-font, $amount: 0.5);
      }

      .MuiInputBase-root  {
        margin-bottom: -2px;
      }

      .MuiFormControl-root  {
        margin-right: auto;
      }

      .MuiIconButton-root {
        width: 24px;
      }
    }

    @at-root #{&}__search-results-holder {
      height: calc(100vh - 550px);
      width: 100%;
      overflow-y: auto;
      border: 1px solid $blue-grey;
      margin-top: -21px;
      border-radius: 0px 0px 5px 5px;
      margin-bottom: 20px;

      .user-result-holder {
        padding: 10px;
        display: flex;
        align-items: center;

        > svg   {
          margin-right: 10px;
          width: 35px;
          height: 35px;
          fill: none;
        }

        .user-result-holder-names {
          display: flex;
          flex-direction: column;

          div:first-child  {
            font-size: $font-xlsmall;
          }
          div:last-child  {
            font-size: $font-xlsmall;
            color: transparentize($color: $color-font, $amount: 0.5);
          }
        }
      }
    }

    @at-root #{&}__selected-users {
      height: 250px;
      overflow-y: auto;

      .selected-user-box  {
        border: 1px solid transparentize($color: $blue-grey, $amount: 0.80);
        padding: 10px;
        @include border-radius(5px);
        margin-bottom: 10px;

        svg {
          margin-right: 10px;
          width: 35px;
          height: 35px;
          fill: none;
        }

        .selected-user-names-box  {
          
          > span  {
            font-size: $font-xlsmall;
          }
          > p  {
            font-size: $font-xlsmall;
            color: transparentize($color: $color-font, $amount: 0.5);
          }
        }
      }

      .fig-close-icon {
        @include svg-icons(15px, 15px, $color-font);
      }
    }
  }
}

.fig-request-a-video-right-side {
  padding-top: 30px;
  padding-right: 20px;

  @at-root #{&}__title  {
    font-size: $font-xlsmall;
    font-weight: $font-semi-bold;
  }

  @at-root #{&}__contentbox  {
    @include border-radius(5px);
    border: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
    margin-top: 20px;

    .video-title  {
      padding: 20px;

      .MuiInputBase-input {
        font-size: $font-regular; 
      }
    }

    .MuiFormLabel-root {
      font-size: $font-regular;
    }

    .textarea-box {
      background-color: $lighter-grey2;
      padding: 20px;

      .MuiInputBase-root  {
        @include border-radius(3px);
        //border: 1px solid transparentize($color: $blue-grey, $amount: 0.5);
        background-color: $color-white;
        width: 100%;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: transparentize($color: $blue-grey, $amount: 0.5);
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: $blue-grey !important;
        border-width: 1px;
      }

      .MuiInputBase-input {
        font-size: $font-xlsmall;
      }

      .MuiFormLabel-root {
        font-size: $font-xlsmall;
      }

      .datepicker {
        border: 1px solid transparentize($color: $blue-grey, $amount: 0.5);
        background-color: $color-white;
        @include border-radius(3px);
        margin-top: 10px;
        padding: 10px 15px 10px 70px;

        .MuiInputLabel-shrink {
          transform: translate(0, 1.5px) scale(1);
        }

        .MuiInputLabel-root {
          top: 18px;
          left: 15px;
        }

        .MuiInput-underline:before, .MuiInput-underline:after  {
          border-bottom: 0px;
        }

        .MuiInputBase-input {
          padding-top: 10px;
          padding-bottom: 4px;
          font-size: $font-xlsmall;
        }
      }

      label + .MuiInput-formControl {
        margin-top: 0px;
      }
    }
  }

  .button-holder  {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .MuiButton-root.Mui-disabled  {
      opacity: 0.5;
    }
  }
}
