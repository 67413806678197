// -----------------------------------------------------------------------------
// This file contains highlights styles.
// -----------------------------------------------------------------------------

.fig-video-details-highlights-main-container {
  margin-top: 10px;
  padding-right: 5px;

  > div:first-child {
    padding-left: 20px;
    height: calc(100vh - #{$video-height} - #{$page-padding} - #{$tabs-height} - #{$player-height} - #{$add-comment-area});
    overflow-x: hidden;
    overflow-y: auto;
  }

  .fig-main-highlights-container {
    box-shadow: none;
    border: 1px solid transparentize($blue-grey, 0.8);
    background-color: $color-white;
    margin-bottom: 6px;
    position: relative;

    .MuiCardHeader-root {
      padding: 20px 0px 0px 16px;

      .MuiCardHeader-content {
        flex: 0 0 auto;
      }

      .MuiCardHeader-action {
        margin-top: 0px;
        margin-bottom: 0px;

                .icons-container    {
                    padding-right: 15px;
                    button  {
                        padding: 6px;
                    }
                }

        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }

        .fig-delete-icon {
          @include svg-icons(16px, 16px, $color-primary);
        }

        .fig-edit-icon {
          @include svg-icons(14px, 14px, $color-primary);
        }
      }

      .MuiTypography-h5 {
        font-family: $font-base;
        font-size: $font-xsmall;
        font-weight: $font-semi-bold;
      }
    }

    .MuiCardHeader-action {
      .MuiButton-text {
        font-family: $font-base;
        font-size: $font-xsmall;
        font-weight: $font-semi-bold;
        padding: 0px;
        color: $color-font;
      }
    }

    .MuiCardContent-root {
      padding-top: 5px;

      &:last-child {
        padding-bottom: 16px;
        padding-top: 0px;
      }

      > span {
        font-family: $font-base;
        font-size: $font-xlsmall;
        color: $color-font;
        line-height: 1.5;
        letter-spacing: normal;
      }

      > button {
        font-family: $font-base;
        color: $color-primary;
        margin-top: 5px;
        font-size: $font-xlsmall;
      }
    }
    .fig-highlight-left-shape {
      position: absolute;
      width: 5px;
      height: calc(100% - 6px);
      border-radius: 3px 0px 0px 3px;
      background-color: $color-font;
      left: 0px;
      top: 0px;
      margin-left: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .fig-buttons-container {
      padding-bottom: 10px;
      > .fig-button:first-child {
        margin-left: 0px;
      }
    }
  }

  .fig-add-highlight-container {
    width: 100%;
    height: 94px;
    border-top: 1px solid transparentize($blue-grey, 0.8);
    padding: 20px 20px 10px 20px;
    position: relative;

    .fig-add-highlight-input {
      label + .MuiInput-formControl {
        margin-top: 20px;
      }

      > label {
        color: $color-font;
        font-size: $font-xlsmall;
        font-family: $font-base;
        font-weight: $font-semi-bold;
      }

      > .MuiInputBase-root input {
        color: $color-font;
        font-size: $font-xlsmall;
        font-family: $font-base;
      }
    }
    .fig-add-highlight-icons {
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
      }
    }

    .fig-select-status {
      position: absolute;
      top: 15px;
      right: 20px;

      div {
        color: $color-font;
        font-size: $font-xsmall;
        font-family: $font-base;
        font-weight: $font-semi-bold;

        &:focus {
          background-color: unset;
        }
      }

      .MuiSvgIcon-root {
        width: 13px;
        height: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01484 0L5.83984 3.7085L9.66484 0L10.8398 1.1417L5.83984 6L0.839844 1.1417L2.01484 0Z' fill='%23002E51'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        top: calc(50% - 6px);
      }

      .MuiInput-underline:before {
        border-bottom: 0px;
      }
    }
  }

  .no-highlights-container {
    font-size: $font-xllarge;
    font-weight: $font-semi-bold;
    text-transform: uppercase;
    color: $light-grey;
    justify-content: center;
    align-items: center;
    display: flex;
    height: calc(100% - 48px); 
  }

  .highlights-filter-container  {
    display: flex;
        justify-content: flex-end;

        .MuiFormControl-root    {
            display: flex;
            flex-direction: row;

            &:first-child   {
                margin-right: 20px;
            }

            label {
                color: $color-font;
                font-size: $font-xsmall;
                font-family: $font-base;
                font-weight: $font-semi-bold;
                margin-top: -16px;
            }
            
            .MuiSvgIcon-root   {
                width: 13px;
                height: 6px;
                background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01484 0L5.83984 3.7085L9.66484 0L10.8398 1.1417L5.83984 6L0.839844 1.1417L2.01484 0Z' fill='%23002E51'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                top: calc(50% - 6px);
            }

            .MuiSelect-select.MuiSelect-select  {
                padding-right: 15px;
            }

            label + .MuiInput-formControl   {
                margin-top: 0px;
            }
        }

        

        .MuiInput-underline:before  {
            border-bottom: 0px;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 0px;
        }
  }
}
