// -----------------------------------------------------------------------------
// This file contains header styles.
// -----------------------------------------------------------------------------
.header-container .header-title {
    font-size: 24px;
    display: flex;
    font-weight: 400;
    color: #2ec7c9;
    font-family: Lobster, serif;
    padding-left: 20px;
}
.header-container {
    display:flex;
}
  
.header-container {
    .header {
        padding: 0;
        display: flex;
        justify-content: space-between;
    }    
} 
  
  .header-container .header-content {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
  }
  
  .header-container .header-name {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }
  
  .header-container .header-name-username {
    text-transform: uppercase;
  }
  
  .header-container .header-name-user-role {
    color: #b6a2de;
    font-size: 15px;
  }
  
  .header-icon {
    padding: 29px 18px;
    background-color: #676363;
    color: #fff;
    height: 100%;
    border: none;
  }
  
  .header-container .header-name {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .header-container .header-name span {
    padding-left: 5px;
  }
  
  .header-container .header-action {
    display: flex;
    justify-content: flex-end;
  }
  
  .header-container .header-action a {
    text-decoration: none;
    color: white;
    font-size: 13px;
  }
  
  .header-container .header-action a:visited {
    text-decoration: none;
  }
  
.side-menu {

    > div {
        background-color: unset;
        border: 0px solid;
        border-bottom: none;
        border-left: none;
        position: relative;
      }
}
  
.side-menu-list {
    padding: 0 !important;
    width: 40px;
    overflow: hidden;

    > a, div {
        padding: 16px 0px 0px 0px !important;

        > .MuiListItemIcon-root {
            
          > svg {
              &.fig-menu-icon {
                @include svg-icons(40px, 40px, $color-white);
              }
            }
        }

        &:first-child {
            padding: 0px !important;

            > .MuiListItemIcon-root {
              padding: 0px;
            }
        }
    }

    > a:hover {
        background-color: transparent !important;
    }

    .Mui-selected {
        background-color: transparent !important;
    }

    > div:last-child {
        padding: 16px 0px 0px 0px !important;

        > .MuiListItemIcon-root {
            padding: 10px;
            min-width: auto;
        }
    }

    > .MuiListItem-button {
        &:hover {
            background-color: transparent !important;
        }
    }
}
  
  .side-menu-list-collapsed {
    width: 60px;
  }
  
 
  
  header {
    height: 100%;
  }
  