div.pt_test {
  position: fixed;
  left: 0;
  top: 0;
  width: 100pt;
  height: 100pt;
}
.fig-accordion {
  .Mui-expanded {
    background-color: $lighter-grey2;
  }
  .MuiAccordionDetails-root {
    padding: 8px 0px 0px;
  }
  .fig-edit-icon {
    width: 14px;
    height: 14px;
    fill: #0051df;
  }
}
.fig-accordion-details {
  padding: 0px 10px 20px 10px;
  background-color: $lighter-grey2;

  .fig-modal-dialog-input {
    > label {
      color: $color-font;
      font-size: $font-xsmall;
      font-family: $font-base;
      font-weight: $font-semi-bold;
      transform: translate(0, 1.5px) scale(1);
    }

    textArea {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
      padding-bottom: 15px;
      padding-top: 10px;
    }

    input {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
      padding-bottom: 15px;
      padding-top: 10px;
    }

    &.MuiInputBase-root {
      color: $color-font;
      font-size: $font-regular;
      font-family: $font-base;
    }
    label + .MuiInput-formControl {
      margin-top: 10px;
    }

    .MuiSelect-select {
      margin-top: 7px;
    }
    //@GINA
    .MuiInput-underline {
      &:before {
        border-bottom: 0px;
      }

      // &:after {
      //   border-bottom: 0px;
      // }

      // &:hover:not(.Mui-disabled):before {
      //   border-bottom: 0px;
      // }
    }
  }

  > div {
    &.row {
      margin-bottom: 25px;
    }
    &.row:last-child {
      margin-bottom: 0px;
    }
  }

  .fig-modal-dialog-buttons {
    display: flex;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 5px;
    }
  }
}

.fig-hl-comments-main-container {
  padding: 15px;
  min-height: 40px;
  position: relative;
  margin-top: 10px;

  .left-side {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    p {
      font-size: $font-xlsmall;
      padding-left: 5px;
      &.fig-user {
        padding-top: 7px;
      }
    }
  }
}
