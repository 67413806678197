// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//Colors
$color-light-grey: #f5f6f8 !default;
$color-border: #9ba7bc !default;
$pdf-grey: #D9D9D9 !default;
$color-font: #050505 !default;
$color-white: #ffffff !default;
$blue-grey: #9ba7bc !default;
$bubble-grey: #f5f6f8 !default;
$color-primary: #0051df !default;
$dark-blue: #1b335e !default;
$pdf-blue: #10254E !default;
$dark-blue2: #050F29 !default;
$icon-deselected: #bfc9dd;
$modal-row-selected: #f4f8fe !default;
$folder-selected: $modal-row-selected;
$light-grey: #f2f2f2 !default;
$lighter-grey: #f1f4fa !default;
$lighter-grey2: #F9FAFB !default;
$lighter-border: #8fa2c3 !default;
$color-popup-border: #cdd3dd !default;
$color-divider: #CAD0DB !default;
$color-red: #f71201 !default;
$lighter-blue: #d9e5fa !default;
$color-pink: #FDEDED !default;

//Fonts
$font-base: 'Inter', sans-serif !default;
$font-OpenSans-EB: 'Open Sans', sans-serif !default;
$font-Arimo: 'Arimo', sans-serif !default;
$font-height: 1.5 !default;

$font-size: 1rem !default; /*Font size: 16px*/
$font-xxllarge: ($font-size * 3.375) !default; /*Font size: 54px*/
$font-xllarge: ($font-size * 1.875) !default; /*Font size: 30px*/
$font-large: ($font-size * 1.5) !default; /*Font size: 24px*/
$font-semi-regular: ($font-size * 1.125) !default; /*Font size: 18px*/
$font-regular: ($font-size * 0.875) !default; /*Font size: 14px*/
$font-xlsmall: ($font-size * 0.75) !default; /*Font size: 12px*/
$font-small: ($font-size * 0.6875) !default; /*Font size: 11px*/
$font-xsmall: ($font-size * 0.625) !default; /*Font size: 10px*/
$font-xxsmall2: ($font-size * 0.5625) !default; /*Font size: 9px*/
$font-xxsmall: ($font-size * 0.5) !default; /*Font size: 8px*/

$font-color: $color-font;
$font-weight-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;



//Main
$no-gutter: 0 !default;
$no-margin: 0 !default;

$transition-base: all 0.3s ease-in-out !default;

//z-index
$zIndex-lvl-1: 990 !default;
$zIndex-lvl0: 1000 !default;
$zIndex-lvl1: 1010 !default;
$zIndex-lvl2: 1020 !default;
$zIndex-lvl3: 1030 !default;
$zIndex-lvl4: 1040 !default;
$zIndex-lvl5: 1050 !default;
$zIndex-lvl6: 1060 !default;
$zIndex-lvl7: 1070 !default;
$zIndex-lvl8: 1210 !default;

$video-height: 337px;
$page-padding: 30px;
$tabs-height: 41px;
$player-height: 72px;
$page-header: 90px;
$add-comment-area: 104px;
