// -----------------------------------------------------------------------------
// This file contains rubric styles.
// -----------------------------------------------------------------------------

.fig-video-details-rubric-main-container  {
    margin-top: 10px;
    padding: 10px 20px 20px 20px;
    
    > div:first-child   {
        > div:nth-child(2)  {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .fig-rubric-input  {

        > label {
            color: $blue-grey;
            font-size: $font-xlsmall;
            font-family: $font-base;
        }

        select {
            color: $color-font;
            font-size: $font-xlsmall;
            font-family: $font-base;
            font-weight: $font-semi-bold;
            min-height: 16px !important;

            + svg   {
                width: 13px;
                height: 6px;
                background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.01484 0L5.83984 3.7085L9.66484 0L10.8398 1.1417L5.83984 6L0.839844 1.1417L2.01484 0Z' fill='%23002E51'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                top: inherit;
            }
        }

        .MuiInput-underline:before  {
            border-bottom: 0px;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 0px;
        }
    }

    .MuiIconButton-label   {
        .fig-delete-icon    {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            margin-right: 5px;
        }
        
    }

    .fig-rubric-category-container  {
        font-family: $font-base;
        margin-bottom: 20px;
        
        b   {
            font-size: $font-xlsmall;
            font-weight: $font-semi-bold;
        }
        .fig-rubric-icon    {
            @include svg-icons(16px, 16px, $color-font);
        }

        @at-root #{&}__box  {
            @include border-radius(5px);
            border: 1px solid transparentize($color: $color-border, $amount: 0.80);
            padding: 20px 16px 20px 16px;
            margin-top: 6px;
            font-size: $font-xlsmall;

            &:first-child   {
                margin-top: 10px;
            }

            b   {
                font-weight: $font-bold;
            }

            .MuiRating-label    {
                margin-bottom: 0px;
            }

            .fig-rating-box {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;

                span:first-child   {
                    svg {
                        width: 18px;
                        height: 18px;
                        background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.76417 0.253738C6.89429 -0.00970939 7.10697 -0.00970939 7.23709 0.253738L8.87789 3.57808C9.00801 3.84153 9.35242 4.09189 9.64312 4.1341L13.3119 4.66848C13.6028 4.71069 13.6686 4.91322 13.458 5.11842L10.8032 7.70613C10.5927 7.91133 10.4612 8.31612 10.5109 8.60575L11.1375 12.2595C11.1872 12.5492 11.0149 12.6742 10.7549 12.5377L7.47356 10.8114C7.21331 10.6746 6.78768 10.6746 6.52744 10.8114L3.24638 12.5363C2.98614 12.6731 2.81407 12.5481 2.86377 12.2582L3.49032 8.60442C3.54002 8.31479 3.40856 7.90973 3.19802 7.7048L0.543246 5.11708C0.332702 4.91188 0.39843 4.70962 0.689397 4.66714L4.35815 4.13277C4.64912 4.09055 4.99352 3.8402 5.12337 3.57675L6.76417 0.253738Z'");
                        background-repeat: no-repeat;
                        margin-right: 5px;
                    }
                }

                .MuiRating-root {
                    color: $dark-blue;
                }
                
                .MuiRating-visuallyhidden   {
                    color: $icon-deselected;
                }

                div:last-child {
                    font-size: $font-size;
                    font-weight: $font-bold;
                }
            }

        }
    }
}

