// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.hand {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.absolute-hide {
  position: absolute;
  top: -9999px;
}

.show {
  display: block;
}

.show-flex {
  display: flex;
  flex-wrap: wrap;
}

.effect {
  @include effect;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

$positions: top right bottom left;

//Creates classes for margins from 10px to 70px
@each $position in $positions {
  @for $i from 1 to 7 {
    .margin-#{$position}--#{$i}0 {
      margin-#{$position}: #{$i}0px !important;
    }
  }
}

//Creates classes for negative margins from 10px to 70px
@each $position in $positions {
  @for $i from 1 to 7 {
    .margin-#{$position}--#{$i}0-negative {
      margin-#{$position}: -#{$i}0px;
    }
  }
}

//Creates classes for margins from 15px to 75px
@each $position in $positions {
  @for $i from 1 to 7 {
    .margin-#{$position}--#{$i}5 {
      margin-#{$position}: #{$i}5px !important;
    }
  }
}

//Creates classes for paddings from 10px to 70px
@each $position in $positions {
  @for $i from 1 to 7 {
    .padding-#{$position}--#{$i}0 {
      padding-#{$position}: #{$i}0px !important;
    }
  }
}

//Creates classes for padding from 10px to 70px
@for $i from 1 to 7 {
  .padding--#{$i}0 {
    padding: #{$i}0px !important;
  }
}


//Creates classes for paddings from 15px to 75px
@each $position in $positions {
  @for $i from 1 to 7 {
    .padding-#{$position}--#{$i}5 {
      padding-#{$position}: #{$i}5px !important;
    }
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.margin-none {
  margin: 0px !important;
}
.margin-top0 {
  margin-top: 0px !important;
}
.padding-none {
  padding: 0px !important;
}
.fig-vertical-middle {
  display: flex;
  align-items: center;
}
.fig-horizontal-center {
  display: flex;
  justify-content: center !important;
}
.fig-space-between {
  display: flex;
  justify-content: space-between !important;
}
.fig-direction-column {
  display: flex;
  flex-direction: column;
}
.fig-direction-row {
  display: flex;
  flex-direction: row;
}
.fig-align-right {
  display: flex;
  justify-content: flex-end;
}
.fig-align-left {
  display: flex;
  justify-content: flex-start;
}

.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}

.opacity-5 {
  opacity: 0.5;
}
.z-index-lvl1 {
  z-index: $zIndex-lvl0;
}

//gina - fix for pointer with custom Select
.MuiFormControl-root {
  .fig-selection-list-pointer.MuiInputBase-root {
    cursor: pointer;
  }
}

.font-italic {
  font-style: italic;
}
