// -----------------------------------------------------------------------------
// This file contains buttons styles.
// -----------------------------------------------------------------------------
.fig-button {
    min-width: 10px;
    height: 25px;
    @include border-radius(3px); 
    border: 1px solid transparentize($blue-grey, 0.50) !important;
    text-align: center;
    color: $color-primary !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    text-transform: capitalize !important;
    font-family: $font-base !important;
    font-size: $font-xsmall !important;

    &.highlights    {
        margin-left: 4px;
        margin-top: 8px;
    }

    &.rubric    {
        margin: 5px;
    }

    &.rubric-primary    {
        background-color: $color-primary;
        color: $color-white !important;
        height: 36px;
        text-transform: uppercase !important;
        font-weight: $font-semi-bold;
        min-width: 110px;
        border: 0px !important;

        &:hover {
            background-color: $dark-blue;
            color: $color-white;
        }
    }

    &.in-modal-dialog   {
        height: 36px;
        text-transform: uppercase !important;
        font-weight: $font-semi-bold;
        min-width: 110px;

        &.delete    {
            background-color: $color-primary;
            border-color: $color-primary !important;
            color: $color-white !important;
        }
    }

    &.ghost-to-dark-blue  {
        height: 36px;
        text-transform: uppercase !important;
        font-weight: $font-semi-bold;
        min-width: 110px;
        color: $dark-blue !important;
        border: 1px solid $dark-blue !important; 

        &:hover {
            background-color: $dark-blue;
            color: $color-white !important;
            height: 36px;
            text-transform: uppercase !important;
            font-weight: $font-semi-bold;
            min-width: 110px;
            border: 0px !important; 
        }
    }

    @at-root #{&}__grey {
        background-color: transparentize($dark-blue, 0.50) !important;
        color: $color-white !important;
        min-width: 100px !important;
    }

    @at-root #{&}__white {
        min-width: 100px !important;
    }

    &.fig-delete-rubric  {
        width: 30px;
        border-radius: 3px;
        margin-top: 5px;
        margin-left: auto;
        padding: 0px;
        display: inline-flex;

        .MuiIconButton-label   {
            width: auto;

            svg {
                width: 12px;
                height: 13px;
            }
        }
    }

    &.video-panel  {
        height: 30px;
        text-transform: uppercase !important;
        width: 100%;
        font-weight: $font-semi-bold;
    }

    &.rubrics  {
        height: 30px;
        text-transform: uppercase !important;
        font-weight: $font-semi-bold;
    }
}


