// -----------------------------------------------------------------------------
// This file contains Upload Form page styles.
// -----------------------------------------------------------------------------

.fig-upload-form-header  {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    margin-left: -20px;
    border-bottom: 1px solid transparentize($color: $blue-grey, $amount: 0.8);
    align-items: center;
    min-height: 77px;

    span {
      font-weight: $font-bold;
    }
}

.fig-upload-form-content  {
    width: 100%;
    margin-top: 40px;
    padding-right: 20px;

    .fig-upload-form-text-row {
      
      span:first-child {
        font-size: $font-regular;
        font-weight: $font-semi-bold;
      }

      span:last-child {
        font-size: $font-regular;
        font-weight: $font-semi-bold;
        color: $color-primary;
      }
    }

    .fig-upload-form-textarea {
      padding: 15px;
      border: 0px;
      width: 100%;
      min-height: 100px;
      max-height: 300px;
      font-size: $font-regular;
      margin-top: 10px;
      margin-bottom: 10px;
    }
}














