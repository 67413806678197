// -----------------------------------------------------------------------------
// This file contains Legend Option Pannel styles.
// -----------------------------------------------------------------------------
.fig-legend-options-pannel  {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    padding: 30px;

    .pannel-title-mainbox   {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        .pannel-title   {
            font-weight: $font-bold;
            font-size: $font-regular;
            text-transform: capitalize;
        }

        .fig-close-icon {
            width: 12px;
            height: 12px;
        }

        .MuiButton-text  {
            padding: 0px;
            min-width: 12px;
        }
    }

    .MuiGrid-container  {
        flex-direction: column;
    }

    .MuiGrid-item    {
        margin-bottom: 30px;

        &:last-child    {
            margin-bottom: 0px;
        }
    }

    .fig-options-pannel-category    {

        @at-root #{&}__row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            &:first-child   {
                margin-bottom: 10px;
            }

            .category-title {
                font-size: $font-regular;
            }

            .MuiButton-root {
                width: 50px;
                min-width: 50px;
                padding-left: 0px !important;
                padding-right: 0px !important;
                font-weight: $font-semi-bold;
                font-size: $font-xsmall !important;
                margin-right: 5px;

                &:last-child    {
                    margin-left: 0px;
                }
            }

            .subcategory-title {
                
                .MuiTypography-body1   {
                    font-size: $font-xlsmall;
                }
            }
            
            .MuiSwitch-sizeSmall    {
                width: 31px;
                height: 18px;
                padding: 0px;

                .MuiSwitch-track    {
                    border-radius: 20px;
                    height: 18px;
                    background-color: $lighter-grey;
                    opacity: 1;
                }

                .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track    {
                    background-color: $color-primary;
                    opacity: 1;
                    border-radius: 20px;
                    height: 18px;
                }

                .MuiSwitch-switchBase {
                    padding: 0px;
                    border: 1px;
                    border-color: $lighter-border;
                    color: $color-white;

                    &.Mui-checked  {
                        color: $color-white;
                        right: 12px;
                        transform: translateX(13px);
                        
                        .MuiSwitch-thumb    {
                            box-shadow: none;
                            border: 1px solid $color-primary;
                            
                        }
                    }
                }

                .MuiSwitch-thumb    {
                    box-shadow: none;
                    border: 1px solid $lighter-border;
                    width: 17px;
                            height: 17px;
                }
            }

            
        }
    }
}


