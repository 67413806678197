// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

* {
    &, &:after, &:before {
        @include border-box;
    }
}



html, body {
    height: 100%;
}

body {
    background-color: $color-light-grey;
    color: $font-color;
    position: relative;
    font-family: $font-base !important;

    .MuiTab-root, .MuiTypography-body1, .MuiTypography-body2   {
        font-family: $font-base !important;
    }

    .MuiAutocomplete-popper {
        font-family: $font-base !important;
    }

    .MuiAutocomplete-option {
        font-size: $font-regular;
        font-family: $font-base;
    }

    .MuiAutocomplete-groupLabel {
        font-family: $font-base;
    }
}

a {
    display: block;
    color: inherit;
    text-decoration: none;
    &:hover, &:focus {
        outline: none;
    }
}

/*
    usage of BEM (Block, Element, Modifier) methodology naming convention for classes
    fig- at the beginning of naming

    .block{} - block
    .block__elem{} - element
    .block--mod{} - modifier
*/

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button {
    &:focus {
        outline: none;
    }
}

.fig-color {
    @at-root #{&}--light-grey {
        color: $color-light-grey;
    }

    
}

.fig-bgk-color {
    @at-root #{&}--light-grey {
        background-color: $color-light-grey;
    }

    
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

hr {
    border-top: 1px solid $color-border;
}

