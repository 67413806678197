// -----------------------------------------------------------------------------
// This file contains Admin Container styles.
// -----------------------------------------------------------------------------
.figaro-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    height: 100vh;
    padding: 30px 30px 30px 20px;
    background-color: $color-light-grey;

  }
  
  .figaro-container {

    .figaro-content {
      
      padding: 0px 0px 0px 20px;
      height: 100%;
      width: 100%;
      margin-left: 20px;
      background-color: $color-white;
      @include border-radius(5px);
    }
  } 
  
  .figaro-collapsed-container {
    margin-left: 70px !important;
  }
  