// -----------------------------------------------------------------------------
// This file contains video details page styles.
// -----------------------------------------------------------------------------

.fig-video-details-left-side    {
    border-right: 1px solid transparentize($blue-grey, 0.80);
    height: 100%;
    
    @at-root #{&}__header   {
        height: 90px;
        border-bottom: 1px solid transparentize($blue-grey, 0.80);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .fig-page-title {
            font-weight: $font-bold;
            font-size: $font-size;
        }

        .fig-date   {
            font-weight: 400;
            font-size: $font-small;
            color: transparentize($color-font, 0.50);
        }

        .fig-icons  {
            padding-right: 20px;
        }
    }

    @at-root #{&}__chartTabs  {
        
        &.MuiTabs-root  {
            min-height: 28px;
            height: 41px;
            //width: 390px;
            overflow: visible;
            background-color: $color-white;
        }
        
        .MuiTab-textColorPrimary {
            color: $color-font;
            font-size: $font-small;
            margin-right: 25px !important;

            @include media-breakpoint-down(md)  {
                margin-right: 0px !important;
            }

            &.Mui-selected  {
                color: $color-font;
                font-weight: $font-semi-bold;
                font-size: $font-small;
                
                span {
                    padding: 0px;
                };
            }
        }

        .MuiTabs-flexContainer  {
            + span  {
                &.PrivateTabIndicator-root-1    {
                    height: 3px;
                }
            }
        
        }

        // > .MuiTabs-fixed    {
        //     overflow-x: auto !important;
        // }
    }

    @at-root #{&}__chartContainer   {
        position: relative;
        left: 0px;
        top: 0px;
        height: calc(100vh - #{$page-padding} - #{$page-header}  - #{$tabs-height} - #{$page-padding});
        width: 100%;
        
        .echarts-for-react {
        width: 100%;    
        }
    }

    @at-root #{&}__face-tagging {
        .new-face-tag-title {
            margin-top: 40px;
            font-size: $font-regular;
            font-weight: $font-semi-bold;
        }

        input {
            font-size: $font-xlsmall;
            font-family: $font-base;
        }
    }    
}
.fig-video-details-right-side    {

    @at-root #{&}__video    {
        padding-left: 20px;
        padding-top: 20px;
        margin-bottom: 10px;
        padding-right: 20px;
        position: relative;
        z-index: $zIndex-lvl1;

        .video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
            @include border-radius(3px);

            video   {
                @include border-radius(3px);    
            }
        }

        .fig-overlay-on-video   {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: transparentize($color: $dark-blue, $amount: 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            > div   {
                padding: 10px 25px;
                background-color: $dark-blue2;
                @include border-radius(100px);
                color: $color-white;
                font-size: $font-xlsmall;
                font-weight: $font-bold;
                margin: 10px;
            }
        }
    }

    @at-root #{&}__transcript    {
        padding-left: 0px;
        
        > .row:first-child  {
            padding-left: 20px;
        }

        .fig-player-top {
            margin-top: 15px;
            position: relative;
            font-family: $font-base;

            .fig-player-top-inner   {
                background-color: transparentize($color: $color-primary, $amount: 0.65);
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-weight: $font-semi-bold;
                padding: 5px;
                border-bottom: 1px dotted transparentize($color: $color-primary, $amount: 0.5);
                padding-left: 15px;
                padding-right: 15px;
                font-size: $font-xlsmall;
            }
        }

        .fig-player-bottom  {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: transparentize($color: $color-primary, $amount: 0.85);
            
            .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot  {
                padding-right: 0px;
                font-family: $font-base;
                font-size: $font-regular;
            }

            input   {
                font-family: $font-base;
                font-size: $font-regular;
            }

            .MuiInput-underline:before  {
                border-bottom: 0px !important;
            }

            .MuiInput-underline:hover:not(.Mui-disabled):before {
                border-bottom: 0px !important;
            }
            .MuiInput-underline:after {
                border-bottom: 0px;
            }
        }

        .fig-transcript-box {
            padding: 0px 20px 0px 20px;
            
            
            > .fig-text-container    {
                padding-top: 15px;
                padding-bottom: 15px;
                overflow: auto;
            }
        }
    }
}

.fig-video-details-main-legend-container {
    height: 25%;
    overflow-y: auto;
    > div    {
        .fig-legend-holder  {
            margin-top: 0px;
            border-top: 1px solid transparentize($blue-grey, 0.80);
            height: 100%;
            
            .fig-legend-left-side   {
                display: flex;
                width: calc(100% - 156px);
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .fig-speakers   {
                width: 100px;
            }

            .fig-category-legend-box    {
                font-size: $font-xsmall;  
                
                width: 100%;

                // &:nth-child(2n+1) {
                //     border-right: 1px solid transparentize($blue-grey, 0.80);
                //     padding-right: 20px;
                //     padding-left: 8px;
                // } 
                // &:nth-child(2n+2) {
                    
                //     padding-left: 20px;
                // }

                &:nth-child(n) {
                    border-right: 1px solid transparentize($blue-grey, 0.80);
                    padding-right: 20px;
                    padding-left: 8px;
                }  

                &:last-child {
                    border-right: 0px solid transparentize($blue-grey, 0.80);
                }  
                
                .fig-container  {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    > .MuiGrid-container    {
                        height: 100%; 
                        flex-direction: row; 
                        flex-wrap: wrap; 
                        overflow-y: auto;
                      
                    }

                    
                    > div:first-child   {
                        padding-bottom: 12px;
                        > span:first-child{
                            font-weight: $font-bold;
                            padding-right: 5px;
                        }
                    }

                    .fig-legend-property-box    {
                        background-color: transparentize($bubble-grey, 0.40);
                        @include border-radius(3px);
                        height: 35px;
                        display: flex;
                        align-items: center;
                        //min-width: 80px;
                        padding-left: 10px;
                        padding-right: 7px;
                        justify-content: space-between;
                        margin-bottom: 4px;

                        > div   {
                            padding-right: 10px;
                            white-space: nowrap;
                        }

                        
                    }

                    > .MuiGrid-container {

                        .MuiGrid-item {
                            margin-right: 4px;
                        }
                    }
                }
            }

            .fig-legend-buttons-main-holder  {
                padding-left: 20px;
                
                padding-right: 20px;
                
                height: 100%;
                border-left: 1px solid transparentize($blue-grey, 0.80);
                
                
                .fig-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    
                    button  {
                        min-width: 115px;
                        height: 25px;
                        border: 1px solid transparentize($blue-grey, 0.50);
                        @include border-radius(3px);
                        margin-bottom: 5px;

                        span {
                            font-size: $font-xsmall;
                            font-weight: $font-semi-bold;
                            text-transform: capitalize;
                            font-family: $font-base;
                        }
                    }
                }
            }

            
        }
    }
}
